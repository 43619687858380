<ft-modal-editor-wrapper #modalEditorWrapper>
    <ng-template #triggerTmpl>
        <ng-content></ng-content>
    </ng-template>
    <div class="ft-calendar-editor">
        <mat-tab-group
            class="mat-tab-group-nav mb-4"
            mat-align-tabs="center"
            animationDuration="0ms"
            [(selectedIndex)]="selectedIndex"
            (selectedIndexChange)="selectedIndexChanged($event)">
            <mat-tab *ngFor="let option of behaviorOptions">
                <ng-template matTabLabel>
                    <span [class]="option.iconClass"></span>
                    <span class="mat-tab-label-text">{{ option.label }}</span>
                </ng-template>
            </mat-tab>
        </mat-tab-group>
        <ft-simple-daterange-picker
            [from]="fromDate"
            [to]="toDate"
            [fromLabel]="'From'"
            [toLabel]="'To'"
            (changed)="onDateRangeChanged($event)"
            [divider]="true"
        ></ft-simple-daterange-picker>
        <ft-simple-timerange-picker
            [from]="fromTime"
            [to]="toTime"
            [fromLabel]="'Start time'"
            [toLabel]="'End time'"
            [fromPlaceholder]="'Enter time (optional)'"
            [toPlaceholder]="'Enter time (optional)'"
            (changed)="onTimeRangeChanged($event)"
            [divider]="true"
        ></ft-simple-timerange-picker>
        <div class="ft2-form-group">
            <label>Recurrence rule</label>
            <ng-select class="ft2-select -large"
                       [(ngModel)]="frequencyOption"
                       [items]="frequencyOptions"
                       [clearable]="false"
                       [searchable]="false"
                       bindLabel="name"
                       name="frequency">
            </ng-select>
        </div>
        <ng-container *ngIf="frequencyOption.value === Frequency.WEEKLY">
            <div class="ft2-input h-between mb-4 -large v-center">
                <label class="ft2-checkbox" *ngFor="let weekDay of weekDaysOptions; let i = index;">
                    <input type="checkbox" [ngModel]="isWeekDayOptionChecked(i)" (ngModelChange)="onWeekdayToggle($event, weekDay)">
                    <span class="ft2-checkbox_control mt-1"></span>
                    <span class="bold-text mt-1">{{ weekDay.name }}</span>
                </label>
            </div>
        </ng-container>
        <ng-container *ngIf="frequencyOption.value === Frequency.MONTHLY_FIXED_DATE">
            <ng-select class="ft2-select -large mb-4"
                       [(ngModel)]="monthFixedDate"
                       [items]="monthFixedDates"
                       [clearable]="false"
                       [searchable]="false"
                       name="monthFixedDate">
            </ng-select>
        </ng-container>
        <ng-container *ngIf="frequencyOption.value === Frequency.MONTHLY_RELATIVE_DAY">
            <div class="d-flex selects-group mb-4">
                <ng-select class="ft2-select -large flex-1"
                           [(ngModel)]="monthRelativePos"
                           [items]="positionOptions"
                           [clearable]="false"
                           [searchable]="false"
                           bindLabel="name"
                           name="monthRelativePos">
                </ng-select>
                <ng-select class="ft2-select -large flex-1"
                           [(ngModel)]="monthRelativeDay"
                           [items]="weekDaysOptions"
                           [clearable]="false"
                           [searchable]="false"
                           bindLabel="name"
                           name="monthRelativeDay">
                </ng-select>
            </div>
        </ng-container>
        <div *ngIf="frequencyOption.freq === RRuleFrequency.DAILY
                || frequencyOption.freq === RRuleFrequency.WEEKLY
                || frequencyOption.freq === RRuleFrequency.MONTHLY"
             class="ft2-input-container d-flex v-center">
            <div (click)="ref?.focus()" class="ft2-input-text">
                Happens every
            </div>
            <input [(ngModel)]="interval"
                   #ref
                   [style.flex]="0"
                   [style.min-width.px]="40"
                   [maxlength]="4"
                   type="number"
                   class="ft2-input -large -no-border -width_auto text-center px-0">
            <div (click)="ref?.focus()" class="ft2-input-text">
                <span *ngIf="frequencyOption.freq === RRuleFrequency.DAILY" >day</span>
                <span *ngIf="frequencyOption.freq === RRuleFrequency.WEEKLY" >week</span>
                <span *ngIf="frequencyOption.freq === RRuleFrequency.MONTHLY" >month</span>(s)
            </div>
        </div>
        <ng-container *ngIf="frequencyOption.value === Frequency.YEARLY_FIXED_DATE">
            <ft-date-picker [(ngModel)]="yearFixedDate"
                            [placeholder]="'Enter date'"
                            name="yearFixedDate">
            </ft-date-picker>
        </ng-container>
        <ng-container *ngIf="frequencyOption.value === Frequency.YEARLY_RELATIVE_DAY">
            <div class="d-flex selects-group mb-4">
                <ng-select class="ft2-select -large flex-1"
                           [(ngModel)]="yearRelativePos"
                           [items]="positionOptions"
                           [clearable]="false"
                           [searchable]="false"
                           bindLabel="name"
                           name="yearRelativePos">
                </ng-select>
                <ng-select class="ft2-select -large flex-1"
                           [(ngModel)]="yearRelativeDay"
                           [items]="weekDaysOptions"
                           [clearable]="false"
                           [searchable]="false"
                           bindLabel="name"
                           name="yearRelativeDay">
                </ng-select>
                <ng-select class="ft2-select -large flex-1"
                           [(ngModel)]="yearRelativeMonth"
                           [items]="monthOptions"
                           [clearable]="false"
                           [searchable]="false"
                           bindLabel="name"
                           name="yearRelativeMonth">
                </ng-select>
            </div>
        </ng-container>
    </div>
</ft-modal-editor-wrapper>
