import { Component } from '@angular/core';
import { IFilterAngularComp } from 'ag-grid-angular';
import { IDoesFilterPassParams, IFilterParams, RowNode } from 'ag-grid-community';
import _ from 'lodash';

@Component({
    selector: 'ft-dropdown-filter',
    templateUrl: './dropdown-filter.component.html',
    styleUrls: ['./dropdown-filter.component.scss']
})
export class DropdownFilterComponent implements IFilterAngularComp {
    params: IFilterParams;
    keys: any[];
    values: boolean[];
    filterParam: any;

    agInit(params: any): void {
        if (!!params.context?.compareParam) this.filterParam = params.context.compareParam

        this.keys = Array.from(
            new Set<any>(params.rowModel.rowsToDisplay
                .reduce((prev, rowNode: RowNode) => {
                    if (_.isEmpty(rowNode.data[params.colDef.field])) prev.push('Empty');
                    else {
                        [rowNode.data[params.colDef.field]].flat().forEach(data =>
                            !!this.filterParam ?
                                prev.push(data[this.filterParam]) :
                                prev.push(data)
                        )
                    }
                    return prev;
                }, [])
            )).sort((a, b) => a.localeCompare(b))
        this.values = new Array(this.keys.length).fill(true)
        this.params = params;
    }

    isFilterActive(): boolean {
        return !this.values.every(bool => bool);
    }

    doesFilterPass(_params: IDoesFilterPassParams): boolean {
        return !!this.params.colDef.field && // A field was specified for filtering on
            [_params.data[this.params.colDef.field]] // Get the field from the data
            .flat() // Flatten the potentially 2d array (i.e. if field was an array)
            .map(data => data?.[this.filterParam] || data) // Get the filtered property
            .map(prop => this.keys.findIndex(key => key === (_.isEmpty(prop) ? "Empty" : prop))) // Get the index of the item matching the filter                
            .some(keyIndex => this.values[keyIndex]) // Check if any of the values for this index are valid
    }

    getModel(): any {
        return { value: this.values };
    }

    setModel(model: any): void {
        if (model && this.keys.includes(model)) {
            this.values.fill(false)
            this.values[this.keys.findIndex(key => key == model)] = true;
        } else this.values.fill(true)

        this.params.filterChangedCallback();
    }

    onChange(i): void {
        this.values[i] = !this.values[i];
        this.params.filterChangedCallback();
    }

    allValuesTrue(): boolean {
        return this.values.every(value => value);
    }

    toggleAll() {
        if (this.values.some(value => !value)) this.values.fill(true);
        else this.values.fill(false);
        this.params.filterChangedCallback();
    }
}