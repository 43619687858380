import { Experiment } from '../models/experiment.model';

export class ExperimentUtilClass {
    static getActualExperiments(experiments: Experiment[]): Experiment[] {
        return (experiments || []).filter(
            (exp) =>
                exp.percentage[0] !== 0 &&
                exp.percentage[0] !== 100
        );
    }

    static getActiveExperimentData(experiments: Experiment[]): Experiment {
        return ExperimentUtilClass.getActualExperiments(experiments).find(exp => exp.is_active);
    }
}
